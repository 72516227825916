import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TemplateDashboard from '../template/dashboard';
import TemplateLogin from '../template/login';
import {routesAdmin, routesCustomer} from './routerProps';
import {setBreadcrumbs, setSelectedMenu} from '../redux/actions/states';
import {fetchTranslations} from '../redux/actions/settings';
import LoadingComponent from '../components/loading'
import ErrorSnackBar from '../components/ErrorSnackBar'
import {fetchConnectedUsers} from '../redux/actions/login';
import branding from "../config/config.json";
import {
  CustomerListView,
  CustomerDetail,
  AddInvoiceFee,
  AddSupplierConnection,
  SupliersList,
  CustomerNew,
  SupplierInvoices,
  SupplierInvoicesDetail,
  Home,
  HomeCustomer,
  Files,
  Invoices,
  AuthBankId,
  AuthBankId_TFA,
  PasswordLogin,
  ForgotPassword,
  Contacts,
  MyInfo,
  UserPageSupplierInvoice,
  UserPageSTELInvoice,
  AuthBankIdHosted
} from './viewsList';

const RouterComp = (props) => {
  const {
    access_token,
    refresh_token,
    setBreadcrumbs,
    setSelectedMenu,
    authed,
    user_admin,
    fetchTranslations,
    translations,
    fetchConnectedUsers,
  } = props;

  const handleNavigationStates = (props, bc, id) => {
    let newBcs = JSON.parse(JSON.stringify(bc.breadcrumbs));
    newBcs.items = newBcs.items.map((item) => {
      let keys = Object.keys(props.match.params);
      let it = JSON.parse(JSON.stringify(item))
      keys.forEach(key => {
        let modKey = '/:' + key;
        it.url = it.url.replace(modKey, '/' + props.match.params[key])
      })
      return it;
    });
    setBreadcrumbs(newBcs)
    setSelectedMenu(id)
  }
  if (window.location.pathname.length < 2) {
    let pageURL = window.location.href;
    pageURL = pageURL[pageURL.length - 1] === '/' ? 'se' : '/se'; //TODO:replace EN by SE
    window.location = pageURL;
  }

  let lang = "/se";
  if (window.location.pathname) {
    if (window.location.pathname.match(/.{3}/g)) {
      lang = window.location.pathname.match(/.{3}/g)[0];
    }
  }

  const RouteWithDashboard = ({component: Component, ...props}) => {
    const {settings, id} = props;
    return <Route {...props} render={routerProps => {
      handleNavigationStates(routerProps, settings, id)
      return <TemplateDashboard {...props} >
        <Component {...routerProps} {...props} />
      </TemplateDashboard>
    }}
    />
  }
  const RouteWithLogin = ({component: Component, ...props}) => {
    return <Route {...props} render={routerProps => {
      return <TemplateLogin {...props} >
        <Component {...routerProps} {...props} />
      </TemplateLogin>
    }}
    />
  }
  useEffect(() => {
    let locale = lang === '/se' ? 'sv-SE' : 'en-US';
    fetchTranslations({lang: locale})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (authed && !user_admin) {
      fetchConnectedUsers({
        access_token,
        refresh_token
      })
    }
    // eslint-disable-next-line
  }, [authed, user_admin])

  useEffect(() => {
    let favIconLink = document.createElement('link');
    favIconLink.rel = 'icon';
    favIconLink.href = branding.favIcon;
    document.head.appendChild(favIconLink);

    document.title = branding.customer;
  }, []);

  if (!translations) {
    return false;
  } else {
    return (
      <>
        <Router>
          {/* {!loaderPage && */}
          <LoadingComponent/>
          <ErrorSnackBar/>
          {authed && user_admin &&
          <Switch>
            <RouteWithDashboard exact path={routesAdmin.home.url} component={Home} settings={routesAdmin.home} id={0}/>
            <RouteWithDashboard exact path={routesAdmin.customers.url} component={CustomerListView}
                                settings={routesAdmin.customers} id={1}/>
            <RouteWithDashboard exact path={routesAdmin.customersNew.url} component={CustomerNew}
                                settings={routesAdmin.customersNew} id={2}/>
            <RouteWithDashboard exact path={routesAdmin.customerDetail.url} component={CustomerDetail}
                                settings={routesAdmin.customerDetail} id={1}/>
            <RouteWithDashboard exact path={routesAdmin.customerAddInvoiceFee.url} component={AddInvoiceFee}
                                settings={routesAdmin.customerAddInvoiceFee} id={1}/>
            <RouteWithDashboard exact path={routesAdmin.customerAddSupplierConnection.url}
                                component={AddSupplierConnection} settings={routesAdmin.customerAddSupplierConnection}
                                id={1}/>
            <RouteWithDashboard exact path={routesAdmin.supplierInvoices.url} component={SupplierInvoices}
                                settings={routesAdmin.supplierInvoices} id={3}/>
            <RouteWithDashboard exact path={routesAdmin.supplierInvoicesDetail.url} component={SupplierInvoicesDetail}
                                settings={routesAdmin.supplierInvoicesDetail} id={3}/>
            <RouteWithDashboard exact path={routesAdmin.suppliers.url} component={SupliersList}
                                settings={routesAdmin.suppliers} id={4}/>
            <RouteWithDashboard exact path={routesAdmin.invoices.url} component={Invoices}
                                settings={routesAdmin.invoices} id={5}/>
            <RouteWithDashboard exact path={routesAdmin.invoicingInvoiceFeeManage.url} component={AddInvoiceFee}
                                settings={routesAdmin.invoicingInvoiceFeeManage} id={5}/>

            <RouteWithDashboard exact path={routesAdmin.files.url} component={Files} settings={routesAdmin.files}
                                id={6}/>

            <Redirect push to={`${lang}`}/>
          </Switch>
          }
          {authed && !user_admin &&
          <Switch>
            <RouteWithDashboard exact path={routesCustomer.home.url} component={HomeCustomer}
                                settings={routesCustomer.home} id={0}/>
            <RouteWithDashboard exact path={routesCustomer.stelInvoices.url} component={UserPageSTELInvoice}
                                settings={routesCustomer.stelInvoices} id={1}/>
            <RouteWithDashboard exact path={routesCustomer.supplierInvoices.url} component={UserPageSupplierInvoice}
                                settings={routesCustomer.supplierInvoices} id={2}/>
            <RouteWithDashboard exact path={routesCustomer.myInfo.url} component={MyInfo}
                                settings={routesCustomer.myInfo} id={3}/>
            <RouteWithDashboard exact path={routesCustomer.contacts.url} component={Contacts}
                                settings={routesCustomer.contacts} id={4}/>
            <Redirect push to={`${lang}`}/>
          </Switch>
          }

          {!authed &&
          <Switch>
            <RouteWithLogin exact path={'/:lang/forgot-password'} component={ForgotPassword}/>
            <RouteWithLogin exact path={'/:lang/login'} component={PasswordLogin}/>
            <RouteWithLogin exact path={'/:lang/bankid'} component={AuthBankId}/>
            <RouteWithLogin exact path={'/:lang/bankid/tfa'} component={AuthBankId_TFA}/>
            <RouteWithLogin exact path={'/:lang'} component={AuthBankId}/>
            <RouteWithLogin exact path={'/:lang/grandid-callback'} component={AuthBankIdHosted}/>
            <Redirect push to={`${lang}`}/>
          </Switch>
          }

        </Router>
      </>
    );
  }

}
RouterComp.propTypes = {
  setBreadcrumbs: PropTypes.func.isRequired,
  setSelectedMenu: PropTypes.func.isRequired,
  authed: PropTypes.bool.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  authed: store.login.authed,
  user_admin: store.login.user_admin,
  translations: store.settings.translations,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setBreadcrumbs,
    setSelectedMenu,
    fetchTranslations,
    fetchConnectedUsers,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RouterComp);
