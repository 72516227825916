import { makeStyles } from "@material-ui/core/styles";
import settings from '../settings';

const drawerWidth = 240;
const theme = (props) => {
  const styles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBar: {
      height: 60,
      backgroundColor: 'white',
      zIndex: theme.zIndex.drawer + 1,
      fontSize: 13,
      color: '#989898',
      textTransform: 'uppercase'
    },
    appBarToolbar: {
      justifyContent: 'space-between',
      paddingLeft: 0,
      height: 60,
      minHeight: 60,
    },
    appBarToolbarUser: {
      width: drawerWidth + 100,
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    appBarToolbarUserName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    appBarToolbarLogout: {
      fontSize: 26,
      fontWeight: 'bold',
      fill: 'rgba(152,152,152,0.4)',
      cursor: 'pointer'
    },
    responsiveMenuToolbar: {
      display: 'inline-flex',
      alignItems: 'center',
      width: drawerWidth,
      borderBottom: '3px solid transparent',
      // marginBottom: -2,

    },
    appBarToolbarLogo: {
      backgroundColor: settings.logoBg,
      backgroundImage: `url("${settings.logoPath}")`,
      width: drawerWidth,
      height: 60,
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80%',
      borderBottom: `3px solid ${settings.color4}`,
    },
    appBarToolbarSmallLogo: {
      backgroundImage: `url("${settings.logoSmallPath}")`,
      width: 90,
      height: 60,
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80%',
    },
    menuOpen: {
      borderBottom: `3px solid ${settings.color4}`,
    },
  appBarToolbarUserIcon: {
    height: 50,
    width: 50,
    borderRadius: '50%'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: 60,
    padding: 15,
  },
  }));
return styles();
}
export default theme;

