import React, {memo} from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import {TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography} from '@material-ui/core';
import StatusChips from '../../StatusChip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import translate from '../../../misc/applyTranslation'
import toMoney from '../../../misc/serializeToMoney'

const CustomersList = (props) => {
  const classes = theme();
  const {
    onClick,
    title,
    elevation,
    data
  } = props;
  const handleClick = (id, e) => {
    if (onClick) {
      onClick(id);
    }
  }
  return (
    <TableContainer component={Paper} className={classes.container} elevation={elevation}>
      {title && <Typography className={classes.tableTitle}>{title}</Typography>}
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{translate('customers/detail', 'supplier_invoices_table_invoice_nr_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_invoice_day_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_due_day_header')}</TableCell>
            <TableCell aling='right'>{translate('customers/detail', 'invoices_table_amount_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_reminder_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_ocr_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_payed_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_pay_amount_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_view_header')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} onClick={handleClick.bind(null, row.id)}>
              <TableCell>{row.ocr}</TableCell>
              <TableCell>{moment.unix(row.inv_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.inv_due).format('YYYY-MM-DD')}</TableCell>
              <TableCell aling='right'>{toMoney(row.inv_sum)}</TableCell>
              <TableCell>
                {row.inv_rem >= '1' && row.inv_ink === '0' &&
                <div className={classes.reminderWrap}>
                  <WarningIcon style={{fill: '#ff9800', marginRight: 5}}/>
                  {moment.unix(row.inv_rem).format('YYYY-MM-DD')}
                </div>
                }
                {row.inv_ink >= '1' &&
                  <div className={classes.reminderWrap}>
                    <ErrorIcon style={{fill: '#f44336', marginRight: 5}}/>
                    {moment.unix(row.inv_ink).format('YYYY-MM-DD')}
                  </div>
                }
              </TableCell>
              <TableCell>{row.ocr}</TableCell>
              <TableCell><StatusChips type={row.inv_paid !== '0' && !!row.inv_paid ? 'green' : 'red'}
                                      label={row.inv_paid !== '0' && !!row.inv_paid ?
                                        moment.unix(row.inv_paid).format('YYYY-MM-DD') :
                                        translate('', 'string_paid_type_false')
                                      }/> </TableCell>
              <TableCell>{toMoney(row.inv_pay_amount)}</TableCell>
              <TableCell align='center'><PlayCircleOutlineIcon/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
CustomersList.propTypes = {
  elevation: PropTypes.number.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
}

export default memo(CustomersList);
