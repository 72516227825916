import APICall from '../../../misc/APICall';
import {APIDownload} from '../../../misc/APICall';
import {renewToken} from '../login'

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_LOADING_INVOICES = 'SET_LOADING_INVOICES';
export const SET_ERROR_INVOICES = 'SET_ERROR_INVOICES';
export const SET_FEES_LIST = 'SET_FEES_LIST';
export const PATCH_FEES_LIST = 'PATCH_FEES_LIST';
export const SET_CUSTOMER_TYPES = 'SET_CUSTOMER_TYPES';
export const ADD_TO_FEES_LIST = 'ADD_TO_FEES_LIST';
export const DELETE_FEE = 'DELETE_FEE';
export const SET_INVOICING_LIST = 'SET_INVOICING_LIST';
export const SET_INVOICING_FILTER = 'SET_INVOICING_FILTER';
export const CLEAR_INVOICING_FILTER = 'CLEAR_INVOICING_FILTER';
export const SET_PENDING_BATCH_LIST = 'SET_PENDING_BATCH_LIST';
export const SET_INVOICED_BATCH_LIST = 'SET_INVOICED_BATCH_LIST';
export const SET_AUTODEBIT_LIST = 'SET_AUTODEBIT_LIST';
export const SET_AUTODEBIT_FILTER = 'SET_AUTODEBIT_FILTER';
export const SET_INVOICED_BATCHES_FILTER = 'SET_INVOICED_BATCHES_FILTER';

export const setSelectedTab = (payload) => {
  return {
    type: SET_SELECTED_TAB,
    payload: payload
  }
}
export const setLoadingInvoices = (payload) => {
  return {
    type: SET_LOADING_INVOICES,
    payload: payload
  }
}
export const setErrorInvoices = (payload) => {
  return {
    type: SET_ERROR_INVOICES,
    payload: payload
  }
}
export const fetchFeesList = (payload, firstTime = true) => {
  let {access_token,} = payload;
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `fees?page=1&limit=9999`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_FEES_LIST,
        payload: res.data,
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchFeesList, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const fetchCustomerTypes = (payload, firstTime = true) => {
  let {access_token,} = payload;
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `customer/types`,
      verb: 'get',
    }).then(res => {
      let arr = Object.keys(res.data);
      dispatch({
        type: SET_CUSTOMER_TYPES,
        payload: arr.map(item => {
          return {id: item, text: res.data[item]}
        }),
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchCustomerTypes, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const tempUpdateFees = (payload) => {
  let {key, id, value} = payload;
  return {
    type: PATCH_FEES_LIST,
    payload: {
      id: id,
      key: key,
      value: value,
    }
  }
}

export const saveFeesChanges = (payload, firstTime = true) => {
  let {
    access_token, body, verb,
  } = payload;
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    APICall({
      access_token: access_token,
      url: `fee`,
      verb: verb,
      body: body
    }).then(res => {
      dispatch(setLoadingInvoices(false))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(saveFeesChanges, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const addNewFee = (payload) => {
  return {
    type: ADD_TO_FEES_LIST,
    payload: {
      accountnumber: "",
      fee_amount: "",
      fee_name: "",
      fee_segment: "3",
      fee_vat: "",
      id: null,
      negative: "0"
    }
  }
}
export const deleteFee = (payload, firstTime = true) => {
  let {access_token, id} = payload;
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `fee?fee_id=${id}`,
      verb: 'delete',
    }).then(res => {
      dispatch(fetchFeesList(payload))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(deleteFee, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}

export const setInvoicingFilter = (payload) => {
  return {
    type: SET_INVOICING_FILTER,
    payload: payload
  }
}
export const clearInvoicingFilter = (payload) => {
  let {access_token, refresh_token,} = payload;
  return async dispatch => {
    dispatch({
      type: CLEAR_INVOICING_FILTER,
    })
    dispatch(
      fetchInvoicingList({
        page: 1,
        limit: 20,
        'f-no-supl': null,
        'f-free-text': '',
        'f-from-date': null,
        'f-to-date': null,
        'f-unpaid-cust': false,
        'f-have-inv': false,
        'f-incl-unrecived': false,
        'f-incl-negative': false,
        'f-status': 'Open',
        'f-fee': null,
        'f-special': null,
        'f-multi-search': '',
        'f-supplier': null,
        access_token: access_token,
        refresh_token: refresh_token,
      })
    )
  }
}
export const fetchInvoicingList = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {page, limit, access_token} = payload;
    let queryString = `?page=${page}&limit=${limit}`
    let queryArr = [
      'f-no-supl', 'f-free-text', 'f-from-date', 'f-to-date', 'f-unpaid-cust',
      'f-have-inv', 'f-incl-unrecived', 'f-incl-negative', 'f-status', 'f-fee',
      'f-special', 'f-multi-search', 'f-supplier'
    ]
    queryArr.forEach(item => {
      if (payload[item] && payload[item].length > 0) {
        queryString += `&${item}=${payload[item]}`
      }
    })
    return await APICall({
      access_token: access_token,
      url: `invoicing/search${queryString}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_INVOICING_LIST,
        payload: {
          data: res.out_data,
          totalPages: Math.ceil(res.total_items/20),
          totalBatches: res.unbatched_invoices,
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchInvoicingList, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}

export const createBatch = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, name, from, to} = payload;
    let queryArr = [
      'f-no-supl', 'f-free-text', 'f-from-date', 'f-to-date', 'f-unpaid-cust',
      'f-have-inv', 'f-incl-unrecived', 'f-incl-negative', 'f-status', 'f-fee',
      'f-special', 'f-multi-search', 'f-supplier'
    ]
    var formdata = new FormData();
    formdata.append("batch_name", name);
    formdata.append("batch_inv_day", from);
    formdata.append("batch_due_day", to);

    queryArr.forEach(item => {
      if (payload[item] && payload[item].length > 0) {
        formdata.append(item, payload[item]);
      }
    })
    return await APICall({
      access_token,
      url: `invoicing/batch`,
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch(fetchInvoicingList(payload))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(createBatch, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}

export const fetchPendingBatches = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token} = payload;
    return await APICall({
      access_token: access_token,
      url: `invoicing/batches?page=1&limit=1000&status=batched`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_PENDING_BATCH_LIST,
        payload: res.data
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchPendingBatches, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const fetchInvoicedBatches = (payload, firstTime = true) => {
  return async dispatch => {
    // dispatch(setLoadingInvoices(true))
    let {access_token} = payload;
    return await APICall({
      access_token: access_token,
      url: `invoicing/batches?page=${payload.page}&limit=10&status=invoiced`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_INVOICED_BATCH_LIST,
        payload: {
          data: res.data,
          totalPages: res.pages
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchInvoicedBatches, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const deletePendingBatch = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, id} = payload;
    return await APICall({
      access_token: access_token,
      url: `invoicing/batch?batch_id=${id}`,
      verb: 'delete',
    }).then(res => {
      dispatch(fetchPendingBatches(payload))
      dispatch(fetchInvoicedBatches(payload))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(deletePendingBatch, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const approvePendingBatch = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, id} = payload;
    var formdata = new FormData();
    formdata.append("batch_id", id);
    return await APICall({
      access_token: access_token,
      url: `invoicing/batch/approve`,
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch(fetchPendingBatches(payload))
      dispatch(fetchInvoicedBatches(payload))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(approvePendingBatch, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const fetchBatchPDF = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, id} = payload;
    return await APIDownload({
      access_token: access_token,
      url: `invoicing/batch/xml?batch_id=${id}`,
      verb: 'get',
    }).then(res => {
      dispatch(fetchPendingBatches(payload))
      return res;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchBatchPDF, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const setAutoDebitFilter = (payload) => {
  return {
    type: SET_AUTODEBIT_FILTER,
    payload: payload
  }
}
export const fetchAutoDebitList = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, page, limit} = payload;
    return await APICall({
      access_token: access_token,
      url: `invoicing/batches?page=${page}&limit=${limit}&status=invoiced&autogiro=1`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_AUTODEBIT_LIST,
        payload: {
          data: res.data,
          totalPages: res.pages,
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchAutoDebitList, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}
export const downloadAutogiro = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingInvoices(true))
    let {access_token, date, batches} = payload;
    return await APIDownload({
      access_token: access_token,
      url: `invoicing/batch/autogiro?pay_date=${date}${batches}`,
      verb: 'get',
    }).then(res => {
      dispatch(setLoadingInvoices(false))
      return res;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(downloadAutogiro, payload, dispatch)
      } else {
        dispatch(setErrorInvoices(error))
      }
    })
  }
}

export const setInvoicedBatchesFilter = payload => {
  return {
    type: SET_INVOICED_BATCHES_FILTER,
    payload: payload
  }
}

