import {
  SET_SELECTED_TAB,
  SET_LOADING_INVOICES,
  SET_ERROR_INVOICES,
  SET_FEES_LIST,
  PATCH_FEES_LIST,
  SET_CUSTOMER_TYPES,
  ADD_TO_FEES_LIST,
  DELETE_FEE,
  SET_INVOICING_LIST,
  SET_INVOICING_FILTER,
  CLEAR_INVOICING_FILTER,
  SET_PENDING_BATCH_LIST,
  SET_INVOICED_BATCH_LIST,
  SET_AUTODEBIT_LIST,
  SET_AUTODEBIT_FILTER,
  SET_INVOICED_BATCHES_FILTER
} from '../actions/invoices';
const initialState = {
  selectedTab: 0,
  loading: false,
  error: null,
  fees: [],
  customer_types: [],
  invoicingFilter: {
    page: 1,
    limit: 20,
    'f-no-supl': null,
    'f-free-text': '',
    'f-from-date': null,
    'f-to-date': null,
    'f-unpaid-cust': '0',
    'f-have-inv': '0',
    'f-incl-unrecived': '0',
    'f-incl-negative': '0',
    'f-status': 'Open',
    'f-fee': null,
    'f-special': null,
    'f-multi-search': '',
    'f-supplier': null,
  },
  invoicingList: {
    data: [],
    totalPages: 0,
    totalBatches: 0,
  },
  pendingBatches: [],
  invoicedBatches:  {
    data: [],
    totalPages: 0
  },
  autoDebitList: {
    data: [],
    totalPages: 0
  },
  autoDebitFilter: {
    page: 1,
    limit: 10,
  },
  invoicedBatchesFilter: {
    page: 1
  }
}
const InvoicesReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload,
      }
    case SET_LOADING_INVOICES:
      return {
        ...state,
        loading: payload,
      }
    case SET_ERROR_INVOICES:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_FEES_LIST:
      return {
        ...state,
        loading: false,
        fees: payload,
      }
    case PATCH_FEES_LIST:
      let { id, key, value } = payload;
      let arr = JSON.parse(JSON.stringify(state.fees));
      arr[id][key] = value;
      return {
        ...state,
        fees: arr,
      }
    case ADD_TO_FEES_LIST:
      return {
        ...state,
        fees: [...state.fees, payload],
      }
    case DELETE_FEE:
      let delarr = JSON.parse(JSON.stringify(state.fees));
      delarr.splice(payload.ind, 1);
      return {
        ...state,
        fees: delarr,
      }
    case SET_CUSTOMER_TYPES:
      return {
        ...state,
        loading: false,
        customer_types: payload,
      }
    case SET_INVOICING_LIST:
      return {
        ...state,
        loading: false,
        invoicingList: payload,
      }
    case SET_INVOICING_FILTER:
      let tempInvF = JSON.parse(JSON.stringify(state.invoicingFilter));
      tempInvF[payload.key] = payload.value;
      return {
        ...state,
        invoicingFilter: tempInvF,
      }
    case CLEAR_INVOICING_FILTER:
      return {
        ...state,
        invoicingFilter: {
          page: 1,
          limit: 10,
          'f-no-supl': null,
          'f-free-text': '',
          'f-from-date': null,
          'f-to-date': null,
          'f-unpaid-cust': false,
          'f-have-inv': false,
          'f-incl-unrecived': false,
          'f-incl-negative': false,
          'f-status': 'Open',
          'f-fee': null,
          'f-special': null,
          'f-multi-search': '',
          'f-supplier': null,
        },
      }
    case SET_PENDING_BATCH_LIST:
      return {
        ...state,
        loading: false,
        pendingBatches: payload,
      }
    case SET_INVOICED_BATCH_LIST:
      return {
        ...state,
        loading: false,
        invoicedBatches: payload,
      }
    case SET_AUTODEBIT_LIST:
      return {
        ...state,
        loading: false,
        autoDebitList: payload,
      }
    case SET_AUTODEBIT_FILTER:
      let tempADF = JSON.parse(JSON.stringify(state.autoDebitFilter));
      tempADF[payload.key] = payload.value;
      return {
        ...state,
        autoDebitFilter: tempADF,
      }
    case SET_INVOICED_BATCHES_FILTER:
      let tempIBF = JSON.parse(JSON.stringify(state.invoicedBatchesFilter));
      tempIBF[payload.key] = payload.value;
      return {
        ...state,
        invoicedBatchesFilter: tempIBF
      }
    default:
      return state;
  }
}
export default InvoicesReducer;