import React, { memo, useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton, BackButton } from '../../components/buttons';
import { TextInput, TextInputWNote, SingleCheck, AutoCompleteSupplier } from '../../components/inputs';
import { CustomerConnectionSupplier as Table } from '../../components/tables';
import Label from '../../components/label';
import Calendar from '../../components/Calendar';
import { useHistory, useParams } from 'react-router-dom';
import { fetchConnectionDetails, submitConnectionDetail, disableConnectionDetail } from '../../redux/actions/customer/customerConnection';
import { fetchSuppliersById, fetchSuppliersList } from '../../redux/actions/suppliers';
import translate from '../../misc/applyTranslation';
import moment from 'moment';
const AddInvoiceFee = (props) => {
  const classes = theme();
  const history = useHistory();
  const params = useParams();
  const { supplier, id } = params;
  const [formData, setFormData] = useState({
    customer_id: undefined,
    supplier_id: undefined,
    fee: 0,
    fee_vat: 0,
    customer_nr_at_supplier: undefined,
    disable_date: undefined,
    auto_release_value: undefined,
    auto_release_value_check: true,
    fee_start_date_calendar: false,
    fee_start_date: moment(),
    org_nr: undefined,
    pg_bg_acc: undefined,
  })
  const {
    name,
    name2,
    access_token,
    refresh_token,
    fetchConnectionDetails,
    fetchSuppliersById,
    supplierData,
    connectionDetails,
    fetchSuppliersList,
    suppliersList,
    submitConnectionDetail,
    disableConnectionDetail,
  } = props;
  const wls = window.location.search;
  const cn = wls.search('cn=') > -1 ? wls.replace('?cn=', '') : false;
  const sn = wls.search('sn=') > -1 ? wls.replace('?sn=', '') : false;
  const [invalidFields, setInvalidFields] = useState([])
  const newForm = supplier.toLowerCase() === 'new';
  useEffect(() => {
    if (!newForm) {
      fetchConnectionDetails({
        access_token,
        refresh_token,
        supplier_id: supplier,
        customer_id: id,
      })
    } else {
      fetchConnectionDetails({
        clear: true,
      })
    }
    if (!newForm || sn) {
      fetchSuppliersById({
        access_token,
        refresh_token,
        id: sn || supplier
      })
    }
    fetchSuppliersList({
      access_token,
      refresh_token,
      page: 1,
      limit: 9999
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (!newForm) {
      setFormData({
        customer_id: id,
        supplier_id: sn || supplier,
        fee: connectionDetails.fee ? connectionDetails.fee / 100 : undefined,
        fee_vat: connectionDetails.fee_vat ? connectionDetails.fee_vat / 100 : 0,
        customer_nr_at_supplier: cn || connectionDetails.custnr_supplier,
        disable_date: connectionDetails.disable_date,
        auto_release_value: connectionDetails.auto_release_value ? connectionDetails.auto_release_value / 100 : undefined,
        auto_release_value_check: !(!connectionDetails.auto_release_value || connectionDetails.auto_release_value === '0'),
        //fee_start_date_calendar: !newForm || connectionDetails.fee_start_date === '0',
        fee_start_date_calendar: connectionDetails.fee_start_date === '0' ? true : false,
        fee_start_date: connectionDetails.fee_start_date !== '0' ? moment.unix(connectionDetails.fee_start_date).toDate() : undefined,
      })
    } else {
      setFormData({
        ...formData,
        customer_id: id,
        supplier_id: sn || (!newForm ? supplier : false),
        customer_nr_at_supplier: cn || '',
      })
    }
    // eslint-disable-next-line
  }, [connectionDetails])

  const validateFields = () => {
    const fields = ['supplier_id', 'fee'];
    const {auto_release_value_check,auto_release_value}=formData;
    let tempInvFields = [];
		fields.forEach((field) => {
  			let value = formData[field];
			if (field === 'fee') {
				if (isNaN(value) || value===''){
					tempInvFields.push(field);
        }
			} else if (!formData[field]) {
				tempInvFields.push(field);
			}
		});
    if(auto_release_value_check && (!auto_release_value || !auto_release_value.toString().length)){
      tempInvFields.push("auto_release_value")
    }
    return tempInvFields;
  }
  const handleSubmit = (e, enable) => {
    try { e.preventDefault() } catch (error) { }
    let vF = validateFields();
    setInvalidFields(vF)
    if (vF.length > 0) {
    } else {
      var formdata = new FormData();
      formdata.append("customer_id", id);
      formdata.append("supplier_id", sn || formData.supplier_id);
      formdata.append("customer_nr_at_supplier", formData.customer_nr_at_supplier);
      formdata.append("fee_vat", Number(formData.fee_vat) * 100 || '0');
      formdata.append("fee", Number(formData.fee) * 100 || '0');
      formdata.append("fee_start_date", !formData.fee_start_date_calendar ? moment(formData.fee_start_date).locale('en').format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ") : '0');
      formdata.append("check_auto_pay", formData.auto_release_value_check ? '1' : '0');
      if (formData.auto_release_value_check) {
        formdata.append("auto_release_value", Number(formData.auto_release_value) * 100 || 0);
      }
      submitConnectionDetail({
        access_token,
        refresh_token,
        customer_id: id,
        supplier_id: sn || formData.supplier_id,
        custnr_supplier: formData.customer_nr_at_supplier,
        verb: newForm || enable ? 'POST' : 'PATCH',
        body: formdata,
      }).then(res => {
        history.goBack();
      })
    }
  }
  const handleDisable = () => {
    disableConnectionDetail({
      access_token,
      refresh_token,
      customer_id: formData.customer_id,
      supplier_id: formData.supplier_id,
      custnr_supplier: formData.customer_nr_at_supplier,
    })
  }
  return (
    <React.Fragment>
      <div className={classes.backContainer}>
        <BackButton onClick={e => history.goBack()} text={translate('', 'button_back')} />
      </div>
      <Paper elevation={4} className={classes.clientBox}>
        <div className='title'>{translate('customers/detail', 'invoices_table_title_customer')} <span>{name + ' ' + name2}</span></div>
        <div className='title'>{translate('supplier-invoices/detail', 'add_supplier_panel_title')}</div>
        {(!newForm || !!sn) && <div className={classes.tableContainer}>
          <Table noPaper newConnection={newForm} data={[{ ...supplierData, ...connectionDetails }]} />
        </div>
        }
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
              {!(!newForm || !!sn) &&
                <React.Fragment>
                  <Label text={translate('supplier-invoices/detail', 'add_supplier_panel_supplier_label')} />
                  {suppliersList &&
                    <AutoCompleteSupplier
                      invalid={invalidFields.includes('supplier_id')}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          supplier_id: e ? e.id : null,
                          org_nr: e ? e.suppl_org : null,
                          pg_bg_acc: e ? e.suppl_bg : null,
                        })
                      }}
                      selected={formData.supplier_id}
                      items={suppliersList.map(item => { return { ...item, text: item.suppl_name } })}
                    />
                  }
                  <Label text={translate('supplier-invoices/detail', 'add_supplier_panel_org_nr_label')} />
                  <TextInput disabled value={formData.org_nr || ''} />
                  <Label text={translate('supplier-invoices/detail', 'add_supplier_panel_pg_bg_account_label')} />
                  <TextInput disabled value={formData.pg_bg_acc || ''} />
                </React.Fragment>
              }
              <React.Fragment>
                {(newForm || !connectionDetails.custnr_supplier) &&
                  <React.Fragment>
                    <Label text={translate('supplier-invoices/detail', 'add_supplier_panel_customer_nr_at_supplier_label')} />
                    <TextInputWNote value={formData.customer_nr_at_supplier} note={translate('supplier-invoices/detail', 'add_supplier_panel_add_later_note')} onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setFormData({
                          ...formData,
                          customer_nr_at_supplier: e.target.value
                        })
                      }
                    }} />
                  </React.Fragment>
                }
                <Label text={translate('supplier-invoices/detail', 'add_supplier_panel_monthly_fee_label')} />
                <TextInputWNote
                  invalid={invalidFields.includes('fee')}
                  note={translate('supplier-invoices/detail', 'add_supplier_panel_vat_warning_label')}
                  value={formData.fee || '0'}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setFormData({
                        ...formData,
                        fee: e.target.value
                      })
                    }
                  }}
                />
                <SingleCheck value={formData.auto_release_value_check}
                  text={translate('supplier-invoices/detail', 'add_supplier_panel_always_release_label')}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      auto_release_value_check: e,
                      auto_release_value: null,
                    })
                  }}
                />
                <TextInputWNote 
                placeholder='' note='kr' value={formData.auto_release_value || ''}
                 disabled={!formData.auto_release_value_check}
                 invalid={invalidFields.includes('auto_release_value')}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setFormData({
                        ...formData,
                        auto_release_value: e.target.value
                      })
                    }
                  }} />
              </React.Fragment>
            </Grid>

            <Grid item xs={12} md={6}>
              <SingleCheck value={formData.fee_start_date_calendar} text={translate('supplier-invoices/detail', 'add_supplier_panel_next_invoice_label')}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    fee_start_date_calendar: e
                  })
                }} />
              {!formData.fee_start_date_calendar &&
                <Calendar
                  value={formData.fee_start_date}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      fee_start_date: e
                    })
                  }} />
              }
            </Grid>
            {!newForm && connectionDetails.disable_date &&
              < Grid item xs={12} md={6} lg={3}>
                <BorderButton success={true} onClick={e => handleSubmit(e, 'enable')} text={translate('', 'button_enable')} />
              </Grid>
            }
            {!newForm && !connectionDetails.disable_date &&
              <Grid item xs={12} md={6} lg={3}>
                <BorderButton warning={true} onClick={e => handleDisable()} text={translate('', 'button_disable')} />
              </Grid>
            }
            <Grid item xs={12} md={6} lg={3}>
              <BorderButton type='submit' text={translate('', 'button_save')} />
            </Grid>
          </Grid>
        </form>
      </Paper >
    </React.Fragment >
  );
}
AddInvoiceFee.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  fetchConnectionDetails: PropTypes.func.isRequired,
  fetchSuppliersById: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  name: store.customer.customerData.name,
  name2: store.customer.customerData.name2,
  supplierData: store.suppliers.supplierData,
  connectionDetails: store.customer.connectionDetails,
  suppliersList: store.suppliers.suppliersList.data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchConnectionDetails,
    fetchSuppliersById,
    fetchSuppliersList,
    submitConnectionDetail,
    disableConnectionDetail,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(AddInvoiceFee));
