import React, { memo, useEffect, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, Drawer, Tooltip, withWidth } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DrawerMenu from '../../components/drawerMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import { BorderSelect } from '../../components/inputs';
import { setLogOut, setUserType, fetchUserData, setSelectedCustomer } from '../../redux/actions/login';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import translate from '../../misc/applyTranslation';

const TemplateDashboard = (props) => {
  const classes = theme();
  const history = useHistory();
  const {
    setLogOut,
    fetchUserData,
    children,
    width,
    setUserType,
    userData,
    access_token,
    refresh_token,
    user_admin,
    connectedCustomers,
    selectedCustomer,
    setSelectedCustomer
  } = props;
  let responsiveTest = (width === 'xs' || width === 'sm');
  const [open, setOpen] = useState(false)
  const handleMenuToggle = () => {
    setOpen(open ? false : true);
  }
  let drawerOpen = (!responsiveTest) || (responsiveTest && open)
  // const toggleUsertype = () => {
  //   setUserType();
  //   history.push('/en')
  // }
  useEffect(() => {
    fetchUserData({
      access_token,
      refresh_token,
      admin: user_admin,
    })
    // eslint-disable-next-line
  }, [])
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.appBarToolbar}>
          {!responsiveTest &&
            <div className={classes.appBarToolbarLogo} />
          }
          {responsiveTest &&
            <div className={`${classes.responsiveMenuToolbar} ${open ? classes.menuOpen : ''}`}>
              <MenuIcon style={{ cursor: 'pointer', marginRight: 20, marginLeft: 10, fontSize: 30 }} onClick={handleMenuToggle} />
              <div className={classes.appBarToolbarSmallLogo} />
            </div>
          }
          <div className={classes.appBarToolbarUser}>
            {!user_admin && connectedCustomers.length > 0 &&
              <BorderSelect
                onChange={(e) => setSelectedCustomer(e)}
                selected={selectedCustomer}
                items={connectedCustomers}
              />
            }
            <img className={classes.appBarToolbarUserIcon} src="/user.png" alt='user' />
            <span className={classes.appBarToolbarUserName}>{userData.name || userData.email}</span>
            <Tooltip title={translate('', 'tooltip_logout')} onClick={setLogOut}><PowerSettingsNewIcon className={classes.appBarToolbarLogout} /></Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      { drawerOpen &&
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerMenu
            closeMenu={() => setOpen(false)}
            isResponsive={responsiveTest}
          />
        </Drawer>
      }
      <main className={classes.content}>
        <Breadcrumbs />
        {children}
      </main>
    </div >
  );
}
TemplateDashboard.propTypes = {
  setLogOut: PropTypes.func.isRequired,
  setUserType: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
  userData: store.login.userData,
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  user_admin: store.login.user_admin,
  connectedCustomers: store.login.connectedCustomers,
  selectedCustomer: store.login.selectedCustomer,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setLogOut,
    setUserType,
    fetchUserData,
    setSelectedCustomer,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(withWidth()(TemplateDashboard)));
